import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import './index.css'
import TermModal from '../TermModal'

// Media
import EditIcon from '../../../icons/EditIcon'
import CheckMarkIcon from '../../../icons/CheckMarkIcon'
import CloseIcon from '../../../icons/CloseIcon'
import CursorAnimationSVG from '../../../icons/CursorAnimationSVG'
import TutorialSVG from '../../../icons/TutorialSVG'
import { FormattedMessage } from 'react-intl'
import { useMessages } from '../../../providers/MessagesProvider'
import { useConfig } from '../../../providers/PrivateConfigProvider'
import UploadIcon from '../../../icons/UploadIcon'

const Report = ({ report, createReport, uploadPDF, testing, isExample }) => {
    const config = useConfig()
    const messages = useMessages()
    const reportRef = useRef(null)

    // Local States
    const [reportText, setReportText] = useState('')
    const [showOriginalPromptingResults, setShowOriginalPromptingResults] = useState(false)
    const [activeTerm, setActiveTerm] = useState(null)
    const [highlightedSpanId, setHighlightedSpanId] = useState(null)
    const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 })
    const [isMobile, setIsMobile] = useState(false)
    const [isChangeReportText, setIsChangeReportText] = useState(false)
    const [isDragging, setIsDragging] = useState(false)

    // Event listeners
    const showDetailInfo = useCallback((event, term, spanId) => {
        if (!event) {
            setActiveTerm(null)
            setHighlightedSpanId(null)
            setModalPosition({ top: 0, left: 0 })
            return
        }
        setActiveTerm(term)
        setHighlightedSpanId(spanId)

        // Вычисляем позицию модального окна
        const spanElement = document.querySelector(`[data-span-id="${spanId}"]`)
        if (spanElement && reportRef.current) {
            const rect = event.target.getBoundingClientRect()

            setModalPosition({
                top: rect.bottom + window.scrollY - 10,
                left: rect.left + window.scrollX
            })
        }
    }, [])

    const handleClick = useCallback(
        (event) => {
            const target = event.target
            if (target.classList.contains('term')) {
                const termId = target.getAttribute('data-term-id')
                const spanId = target.getAttribute('data-span-id')
                const term = report.terms.find((t) => t.id === Number(termId))
                showDetailInfo(event, term, spanId)
            } else if (!target.closest('.term-modal-content')) {
                showDetailInfo(null)
            }
        },
        [report.terms, showDetailInfo]
    )

    const insertTerms = useMemo(() => {
        let newText = report.text
        const sortedTerms = [...report.terms].sort((a, b) => b.start_pos - a.start_pos)
        let spanCounter = 0
        sortedTerms.forEach((term) => {
            const spanId = `term-span-${spanCounter}`
            newText =
                newText.slice(0, term.start_pos) +
                `<span class='term ${term.dangerous ? 'dangerous' : ''}' data-term-id='${term.id}' data-span-id='${spanId}'>` +
                newText.slice(term.start_pos, term.end_pos) +
                '</span>' +
                newText.slice(term.end_pos)
            spanCounter++
        })
        return newText
    }, [report.text, report.terms])

    const changeReportText = () => {
        setIsChangeReportText(!isChangeReportText)
        if (!isChangeReportText) {
            setReportText('')
        }
    }

    const onChangeReportText = (e) => setReportText(e.target.value)

    const handleCreateReport = async () => {
        if (reportText.length > 0) {
            await createReport({ text: reportText.replaceAll('\n', '<br>') })
            setReportText('')
        }
        setIsChangeReportText(false)
    }

    const onUploadPDF = async (file) => {
        const data = new FormData()
        data.append('file', file)
        await uploadPDF(data)
    }

    // Drag & Drop events
    const handleDragOver = (e) => {
        e.preventDefault()
        setIsDragging(true)
    }

    const handleDragEnter = (e) => {
        e.preventDefault()
        setIsDragging(true)
    }

    const handleDragLeave = (e) => {
        e.preventDefault()
        setIsDragging(false)
    }

    const handleDrop = async (e) => {
        e.preventDefault()
        setIsDragging(false)
        const file = e.dataTransfer.files[0]
        await onUploadPDF(file)
    }

    // Function to check if the device is mobile
    const checkIsMobile = () => {
        return window.innerWidth <= 1025 // You can customize the breakpoint (768px is a common mobile breakpoint)
    }

    useEffect(() => {
        // Set the initial state on component mount
        setIsMobile(checkIsMobile())

        // Event listener for window resize to update `isMobile`
        const handleResize = () => {
            setIsMobile(checkIsMobile())
        }

        window.addEventListener('resize', handleResize)

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        if (isChangeReportText) {
            const textarea = document.getElementById('report-textarea')
            textarea.style.minHeight = `${textarea.scrollHeight}px`
        }
    }, [isChangeReportText])

    return (
        <div className={`report-and-term`} onClick={handleClick} ref={reportRef}>
            <div
                className={'report'}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {isDragging ? (
                    <div className={'dragging'}>
                        <div>
                            <UploadIcon width={120} height={120} />
                            <h3>
                                <FormattedMessage id={'upload_pdf'} />
                            </h3>
                        </div>
                    </div>
                ) : (
                    <>
                        {config.report_text_changing && !testing && (
                            <div className='report-edit-block'>
                                {isChangeReportText ? (
                                    <div className='buttons'>
                                        <button
                                            className={`report-action-button mr ${isChangeReportText && 'active'} ${reportText.length > 0 && 'save'}`}
                                            onClick={handleCreateReport}
                                        >
                                            <CheckMarkIcon />
                                            <FormattedMessage id={'save'} />
                                        </button>
                                        <button
                                            className={`report-action-button ${isChangeReportText && 'active'} ${reportText.length > 0 && 'save'}`}
                                            onClick={changeReportText}
                                        >
                                            <CloseIcon />
                                            <FormattedMessage id={'cancel'} />
                                        </button>
                                    </div>
                                ) : (
                                    <div className={'buttons-edit-upload'}>
                                        {isExample && (
                                            <button
                                                className={`report-action-button mr ${isChangeReportText && 'active'} ${reportText.length > 0 && 'save'}`}
                                                onClick={changeReportText}
                                            >
                                                <EditIcon />
                                                <FormattedMessage id={'change_text'} />
                                            </button>
                                        )}
                                        <input
                                            hidden
                                            type='file'
                                            id={'pdf_file'}
                                            accept={'image/jpeg, application/pdf'}
                                            onChange={(e) => onUploadPDF(e.target.files[0])}
                                        />
                                        <label htmlFor='pdf_file' className={'report-action-button'}>
                                            <UploadIcon />
                                            <FormattedMessage id={'upload_pdf'} />
                                        </label>
                                    </div>
                                )}
                            </div>
                        )}
                        {isChangeReportText ? (
                            <div className={'textarea-block'}>
                                <textarea
                                    id={'report-textarea'}
                                    className={`report-textarea`}
                                    placeholder={messages['insert_report_text']}
                                    defaultValue={
                                        reportText.length > 0 ? reportText.replaceAll('<br>', '\n') : report.text.replaceAll('<br>', '\n')
                                    }
                                    onChange={onChangeReportText}
                                    contentEditable={true}
                                />
                            </div>
                        ) : (
                            <>
                                <div className='click-instruction mobile'>
                                    <p>
                                        <FormattedMessage id={'click-instruction-1'} />{' '}
                                        <span className={'term'}>
                                            <FormattedMessage id={'click-instruction-2'} />
                                            <span className={'term with-image'}>
                                                <FormattedMessage id={'click-instruction-3'} />
                                            </span>
                                        </span>{' '}
                                        <FormattedMessage id={'click-instruction-4'} />
                                    </p>
                                    <CursorAnimationSVG />
                                </div>
                                <div className={'text'} dangerouslySetInnerHTML={{ __html: insertTerms }} />
                                {testing && report.result_stage_1 && (
                                    <div className={`prompt_testing_results`}>
                                        <button onClick={() => setShowOriginalPromptingResults(!showOriginalPromptingResults)}>
                                            {' '}
                                            {!showOriginalPromptingResults ? 'Показать' : 'Скрыть'} оригинальный ответ от ЛЛМ
                                        </button>
                                        <div className={`stage_results ${showOriginalPromptingResults ? 'show' : ''}`}>
                                            <h3>
                                                <FormattedMessage id={'result_stage_1'} />
                                            </h3>
                                            <div className={'text'} dangerouslySetInnerHTML={{ __html: report?.result_stage_1 }} />
                                            <h3>
                                                <FormattedMessage id={'result_stage_2'} />
                                            </h3>
                                            <div className={'text'} dangerouslySetInnerHTML={{ __html: report?.result_stage_2 }} />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
            <div className={'term-modal-container'} style={{ display: isMobile ? (activeTerm ? 'block' : 'none') : 'block' }}>
                {activeTerm ? (
                    <TermModal
                        key={activeTerm.id + highlightedSpanId}
                        activeTerm={activeTerm}
                        onClose={() => showDetailInfo(null)}
                        position={modalPosition}
                        isMobile={isMobile}
                    />
                ) : (
                    <div className={'tutorial'}>
                        <div>
                            <TutorialSVG />
                            <p className='tutorial-text desktop'>
                                <FormattedMessage id={'click-instruction-1'} />{' '}
                                <span className={'term'}>
                                    <FormattedMessage id={'click-instruction-2'} />
                                    <span className={'term with-image'}>
                                        <FormattedMessage id={'click-instruction-3'} />
                                    </span>
                                </span>{' '}
                                <FormattedMessage id={'click-instruction-4'} /> <br />
                            </p>
                        </div>
                    </div>
                )}
            </div>
            <style jsx={'true'}>{`
                .term[data-span-id='${highlightedSpanId}'] {
                    box-shadow:
                        inset 0 -1.25em #6ff7d3,
                        inset 0 -1.25em rgba(221, 253, 245, 0.73);
                    color: #000000;
                    transition: background-color 0.3s ease;
                }

                .term.dangerous[data-span-id='${highlightedSpanId}'] {
                    color: #000;
                    box-shadow:
                        inset 0 -1.25em #febebe,
                        inset 0 -1.25em rgba(255, 233, 233, 0.66);
                }
            `}</style>
        </div>
    )
}

export default Report
