import React, { useEffect, useState } from 'react'
import {
    useGetExampleReportMutation,
    useCreateReportMutation,
    useUploadPDFReportMutation,
    useTaskStatusMutation,
    setLoadingTrue,
    setLoadingFalse
} from '../../redux'
import Report from '../../components/Highlighting/Report'
import { errorAlert } from '../../components/popups/allerts'
import { useDispatch } from 'react-redux'

function Index() {
    const dispatch = useDispatch()
    const [report, setReport] = useState(null)

    const [fetchReportRequest] = useGetExampleReportMutation()
    const [createReportRequest] = useCreateReportMutation()
    const [uploadPDFRequest] = useUploadPDFReportMutation()
    const [taskStatusRequest] = useTaskStatusMutation()

    const catchError = async (req) => {
        dispatch(setLoadingTrue())
        const res = await req()
        if (res.error) {
            dispatch(setLoadingFalse())
            return errorAlert(res.error.status, res.error.data?.message || res.error.data?.detail || res.error.data)
        }
        dispatch(setLoadingFalse())
        return res
    }

    const fetchExampleReport = async () => {
        const response = await catchError(fetchReportRequest)
        if (response) {
            setReport(response.data)
            return response
        }
    }
    const checkTaskStatus = async (task_id) => {
        dispatch(setLoadingTrue())
        const interval = setInterval(async () => {
            const res = await taskStatusRequest(task_id)
            if (res.error?.data?.status === 'failed') {
                dispatch(setLoadingFalse())
                clearInterval(interval)
                return errorAlert(res.error.data.error)
            }

            if (res.data?.status === 'success') {
                setReport(res.data.result)
                clearInterval(interval)
                dispatch(setLoadingFalse())
            }
        }, 2000)
    }
    const createReport = async (data) => {
        const response = await catchError(() => createReportRequest(data))
        if (response) {
            setReport(response.data)
            return response
        }
    }

    const uploadPDF = async (data) => {
        const response = await catchError(() => uploadPDFRequest(data))
        if (response) {
            await checkTaskStatus(response.data.task_id)
        }
    }

    useEffect(() => {
        fetchExampleReport()
    }, [])

    return (
        <div className='container'>{report && <Report createReport={createReport} report={report} uploadPDF={uploadPDF} isExample />}</div>
    )
}

export default Index
